<template functional>
  <div class="tw-h-screen tw-grid tw-place-items-center tw-bg-white">
    <div class="tw-text-center">
      <h1>Sorry, you don't have access to this application.</h1>
      <a href="/authentication/logout">
        Go back to login
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccessDenied'
}
</script>
